import {defineStore} from 'pinia'

export type Notification = {
    id: number
    type: string
    data: {
        content: string
    }
    created_at: string
    read_at: string | null
}
export const useNotificationStore = defineStore('notifications', {

    state: () => ({
        timeline: useAsyncState(() => $lara.get('/app/spa/notifications') as Promise<Notification[]>, []),
    }),
    getters: {
        unreadNotificationsCount(state) {
            return state.timeline.state.filter(notification => !notification.read_at).length
        },
    },

    actions: {
        async markAllAsRead() {
            await $lara.put('/app/spa/notifications/markAsRead').then(async () => {
                await this.$state.timeline.execute()
            })
        },
        async refreshTimeline() {
            await this.$state.timeline.execute()
        }
    }
})