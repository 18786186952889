<template>
    <Disclosure
        v-slot="{ open }"
        as="div">
        <h3>
            <DisclosureButton class="group relative flex w-full items-center justify-between py-6 text-left">
                <span>
                    <Icon
                        class="mx-2"
                        :class="iconColor"
                        :name="logTypeDefinition.icon" />
                    <span class="text-sm font-medium">{{
                        $t('requestLog.' + logType, logItems.length)
                    }}</span>
                </span>
                <span class="ml-6 flex items-center">
                    <Icon :name="open ? 'heroicons:chevron-up' : 'heroicons:chevron-down'" />
                </span>
            </DisclosureButton>
        </h3>
        <DisclosurePanel
            as="div"
            class="pb-6 px-6">
            <!-- TODO stylize -->
            <ul role="list">
                <li
                    v-for="item in logItems"
                    :key="item">
                    <small>
                        <pre>> {{ item.message }}</pre>
                    </small>
                </li>
            </ul>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup>
import {requestLogTypes} from '@/utils/requestLogTypes'

const props = defineProps({
    logType: {
        type: String,
        default: ''
    },
    logItems: {
        type: Array,
        default: () => []
    }
})

const logTypeDefinition = computed(() => {
    return {
        icon: 'heroicons:exclamation-circle',
        color: 'gray',
        ...(requestLogTypes[props.logType] ?? {})
    }
})

const iconColorMap = new Map([
    ["gray", "text-gray-500"],
    ["success", "text-success-500"],
    ["info", "text-info-500"],
    ["warning", "text-warning-500"],
    ["danger", "text-danger-500"]
])

const iconColor = computed(() => {
    return iconColorMap.get(logTypeDefinition.value.color)
})
</script>