import {$getModuleColor, $getModuleIcon} from "./appModules"

export const sidebarMenu: SidebarMenu = [
    {
        title: 'default',
        menu: [
            {
                title: 'menu.dashboard',
                path: '/',
                icon: $getModuleIcon('dashboard'),
                module: 'dashboard',
            },
            {
                title: 'menu.orders',
                path: 'orders',
                icon: $getModuleIcon('orders'),
                module: 'orders',
            },
            {
                title: 'menu.organisations',
                path: 'organisations',
                ability: ['update', 'organisations'],
                icon: $getModuleIcon('organisations'),
                module: 'organisations',
            },{
                title: 'menu.baseData',
                path: 'baseData',
                icon: $getModuleIcon('baseData'),
                module: 'baseData',
                children: [
                    {
                        title: 'menu.costCenters',
                        path: 'baseData-costCenters',
                        icon: $getModuleIcon('baseData'),
                        module: 'baseData',
                        ability: ['view', 'costCenters']
                    },
                    {
                        title: 'menu.mailTemplates',
                        path: 'settings-mailTemplates',
                        icon: $getModuleIcon('baseData'),
                        module: 'baseData',
                        ability: ['viewAny', 'mailTemplates']
                    },
                    {
                        title: 'menu.categories',
                        path: 'baseData-categories',
                        icon: $getModuleIcon('baseData'),
                        module: 'baseData',
                        ability: ['viewAny', 'categories']
                    },
                    {
                        title: 'menu.users',
                        module: 'users',
                        path: 'users',
                        icon: $getModuleIcon('settings'),
                        ability: ['update', 'users']
                    }
                ]
            }
        ]
    },
]