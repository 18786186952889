<template>
    <div class="flow-root">
        <div
            v-if="timeline.isLoading"
            class="w-full text-center">
            <LoadingSpinner
                size="lg"
                class="text-gray-400" />
        </div>
        <div
            v-else-if="timeline.state.length === 0"
            class="text-center">
            <Icon
                class="h-12 w-12 text-gray-400"
                name="heroicons:inbox"
                aria-hidden="true" />
            <h3 class="mt-2 text-sm font-semibold text-gray-900">
                {{ $t('notifications.emptyTitle') }}
            </h3>
            <p class="mt-1 text-sm text-gray-500">
                {{ $t('notifications.emptyText') }}
            </p>
        </div>
        <ul
            v-else
            role="list"
            class="-mb-8">
            <li
                v-for="(notification, notificationIdx) in timeline.state"
                :key="notification.id">
                <div class="relative pb-8">
                    <span
                        v-if="notificationIdx !== timeline.state.length - 1"
                        class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true" />
                    <div class="relative flex space-x-3">
                        <div>
                            <span
                                class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-gray-900"
                                :class="getIconBackgroundByType(notification.type)">
                                <Icon
                                    :name="getIconByType(notification.type)"
                                    class="h-5 w-5 text-white"
                                    aria-hidden="true" />
                            </span>
                        </div>
                        <div
                            class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                            <div>
                                <p
                                    class="text-sm"
                                    :class="notification.read_at ? 'text-gray-500 dark:text-gray-400' : 'text-gray-700 dark:text-gray-200 font-semibold'">
                                    {{ notification.data.content }}
                                </p>
                            </div>
                            <div
                                class="whitespace-nowrap text-right text-sm text-gray-500 dark:text-gray-400">
                                <time :datetime="notification.created_at">
                                    {{ getDateFromNow(notification.created_at) }}
                                </time>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import {useNotificationStore} from "@/store/notifications"

const {timeline} = useNotificationStore()

const dayjs = useDayjs()
const getDateFromNow = (isoDate) => {
    return dayjs(isoDate).fromNow()
}

const iconMap = new Map([
    ["App\\Notifications\\UserCreatedNotification", "heroicons:user-plus"],
])
const getIconByType = (type) => {
    return iconMap.get(type)
}

const iconBackgroundMap = new Map([
    ["App\\Notifications\\UserCreatedNotification", "bg-gray-400"],
])

const getIconBackgroundByType = (type) => {
    return iconBackgroundMap.get(type)
}

onUnmounted(() => {
    useNotificationStore().markAllAsRead()
})
</script>