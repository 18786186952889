<template>
    <ModuleScope
        class="flex gap-2 items-center rounded text-white px-2 py-1 font-bold"
        :class="moduleColors"
        :module="module">
        <div
            class="flex items-center"
            :class="{'sm:hidden': !complete && !reduced}">
            <slot name="icon">
                <Icon
                    :name="icon"
                    class="w-5 h-5" />
            </slot>
        </div>
        <small :class="{'hidden': !complete || reduced, 'sm:inline': !complete && !reduced}">
            <slot>{{ label }}</slot>
        </small>
        <slot name="append" />
    </ModuleScope>
</template>

<script setup lang="ts">
const props = defineProps({
    label: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: true
    },
    module: {
        type: String
    },
    complete: {
        type: Boolean,
        default: false
    },
    reduced: {
        type: Boolean,
        default: false
    },
    grayedOut: {
        type: Boolean,
        default: false
    }
})

const moduleColors = computed(() => {
    if(props.grayedOut) return 'bg-gray-300 dark:bg-gray-700'
    if(props.module) return 'bg-primary-500 dark:bg-primary-700'
    return 'bg-primary dark:bg-primary'
})
</script>

<style scoped>

</style>