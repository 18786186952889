<template>
    <BaseModal
        v-model="showLog"
        :title="$t('requestLog.title')"
        size="lg">
        <div
            v-if="requestLog && requestLog.groupedByType"
            class="divide-y divide-gray-200 border-t mt-6">
            <LogType
                v-for="(log, type) in requestLog.groupedByType"
                :key="type"
                :log-type="type"
                :log-items="log" />
        </div>
    </BaseModal>
</template>

<script setup>
import LogType from '@/components/global/RequestLog/LogType'

const requestLog = useState('requestLog')
const showLog = computed({
    get: () => !!requestLog.value,
    set: (value) => {
        if (!value) {
            requestLog.value = null
        }
    }
})
</script>