<template>
    <div>
        <TransitionRoot
            as="template"
            :show="layoutStore.isSidebarOpen">
            <Dialog
                as="div"
                class="relative z-50 lg:hidden"
                @close="layoutStore.isSidebarOpen = false">
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full">
                        <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0">
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button
                                        type="button"
                                        class="-m-2.5 p-2.5"
                                        @click="layoutStore.isSidebarOpen = false">
                                        <span class="sr-only">Close sidebar</span>

                                        <Icon
                                            name="heroicons:x-mark"
                                            class="h-6 w-6 text-white"
                                            aria-hidden="true" />
                                    </button>
                                </div>
                            </TransitionChild>

                            <MainMenu />
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <MainMenu />
        </div>
    </div>
</template>

<script setup>
import MainMenu from '@/components/layouts/MainMenu'
import {useLayoutStore} from "@/store/layout"

const layoutStore = useLayoutStore()
</script>