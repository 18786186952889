<template>
    <div class="flow-root">
        <ul role="list">
            <li
                v-for="(releaseNote, releaseNoteIdx) in localeReleaseNotes"
                :key="releaseNote.release">
                <div class="relative pb-8">
                    <span
                        v-if="releaseNoteIdx !== localeReleaseNotes.length - 1"
                        class="absolute left-4 top-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true" />
                    <div class="relative flex items-start space-x-3">
                        <div class="relative">
                            <div
                                class="flex items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:ring-gray-700"
                                :class="{
                                    'size-8' : releaseNoteIdx === 0,
                                    'size-6 ml-1' : releaseNoteIdx !== 0,
                                    'bg-neutral-500': releaseNote.type === 'bugfix',
                                    'bg-primary': releaseNote.type === 'minor',
                                    'bg-success-500': releaseNote.type === 'major'
                                }">
                                <Icon
                                    :name="getIconByType(releaseNote.type)"
                                    :class="{
                                        'w-5 h-5' : releaseNoteIdx === 0,
                                        'w-4 h-4' : releaseNoteIdx !== 0,
                                    }"
                                    class="text-white" />
                            </div>
                        </div>
                        <div class="min-w-0 flex-1">
                            <div>
                                <div class=" flex flex-row items-center gap-2">
                                    <span
                                        class="font-semibold text-gray-900 dark:text-white"
                                        :class="releaseNoteIdx === 0 ? 'text-normal' : 'text-sm'">{{
                                            releaseNote.release
                                        }}</span>
                                    <template v-if="releaseNote.tags && releaseNote.tags.length">
                                        <span
                                            v-for="tag in releaseNote.tags"
                                            :key="'tag-' + releaseNote.release + '-' + tag"
                                            class="inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium text-white"
                                            :class="{
                                                'bg-neutral-500': releaseNote.type === 'bugfix' && releaseNoteIdx === 0,
                                                'bg-primary': releaseNote.type === 'minor' && releaseNoteIdx === 0,
                                                'bg-success-500': releaseNote.type === 'major' && releaseNoteIdx === 0,
                                                'bg-slate-400': releaseNoteIdx !== 0
                                            }">
                                            {{ tag }}
                                        </span>
                                    </template>
                                </div>
                                <BaseDate
                                    class="text-xs text-sm text-gray-500 dark:text-gray-400"
                                    :date="releaseNote.date" />
                            </div>
                            <div
                                v-if="releaseNote.notes && releaseNote.notes.length"
                                class="mt-2 text-sm text-gray-700 dark:text-gray-200"
                                :class="{'font-semibold' : releaseNoteIdx === 0}">
                                <ul class="list-['✔']">
                                    <li
                                        v-for="featureNote in releaseNote.notes"
                                        :key="featureNote.description"
                                        class="pl-2">
                                        <span
                                            v-if="featureNote.html"
                                            v-html="featureNote.description" />
                                        <span v-else>{{ featureNote.description }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div
                                v-if="releaseNote.fixes && releaseNote.fixes.length"
                                class="mt-4 text-sm">
                                <div class="font-semibold mb-2">
                                    {{ $t('users.info.bugfixes') }}
                                </div>
                                <ul class="list-disc list-inside">
                                    <li
                                        v-for="bugfixNote in releaseNote.fixes"
                                        :key="bugfixNote.description"
                                        class="pl-2">
                                        {{ bugfixNote.description }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>

import {useLanguage} from '@/composables/useLanguage'

const iconMap = new Map([
    ["bugfix", "heroicons:bug-ant"],
    ["minor", "heroicons:check"],
    ["major", "bi:box-seam"]
])

const getIconByType = (type) => {
    return iconMap.get(type)
}

const {lang} = useLanguage()
const localeReleaseNotes = computed(() => {
    return !lang?.value || (lang.value === 'de') ? release_notes_de : release_notes_en
})
</script>
